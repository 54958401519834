#root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding-top: 10vh;
  padding-bottom: 15vh;

  overflow: auto;
}


a {
  border: thick outset gray;
  border-radius: 1rem;
  padding: 0.5rem 0.9rem;
  background-color: white;
  color: black;
  text-decoration: none;
}

a:hover {
  border-style: solid;
}

a:active {
  border-style: inset;
}


li {
  list-style-type: none;
  padding: 1rem 2rem;
}


header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10vh;
  z-index: 2;

  background-color: #282c34;
  padding: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  font-size: calc(10px + 2vmin);
  color: white;
}


footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  
  background-color: #282c34;
  min-height: 10vh;
  margin: 0;
  padding: 0.1rem;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  font-size: calc(10px + 2vmin);
  color: white;
}

footer > * {
  margin: 0.1rem;
}


main {
  position: relative;
  
  padding: 0 0 13rem 0;
}


select {
  border-radius: 0.3rem;
}


.flex.col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.flex.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.flex.wrap {
  flex: 0 0 100%;
}


.highlight {
  border-color: yellow;
}


.plain {
  background-color: transparent;
  border: none;
}


.prayer.selectors {
  background-color: #8a919f;
}

.prayer.selectors > * {
  max-width: 40%;
  margin: 1rem;
  padding: 1rem;
  background-color: white;
  border-radius: 2rem;
}

.prayer.viz {
  background-color: #ffebfe;
  margin: 1rem;
  padding: 1rem;
}

.prayer.link {
  display: inline-block;
  background-color: #f6ebff;
  border: thick ridge #f6ebff;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  margin: 0.5rem 1rem;
}


.shortcut {
  margin: 0.1rem;
  padding: 0.1rem 0.2rem;
  border: thick outset lightblue;
  border-radius: 0.7rem;
}

.shortcut:hover {
  border-style: solid;
}

.shortcut:active {
  border-style: inset;
}